/**
 * Downloads a file from a Blob, Response, or data URL.
 * @param {Blob|Response|string} data - The data to download (Blob, Response object, or data URL).
 * @param {string} [filename] - The name of the downloaded file (default: `download-{timestamp}`).
 */
export async function downloadFile(data, filename = `download-${Date.now()}`) {
  let objectUrl;

  if (data instanceof Response) {
    objectUrl = URL.createObjectURL(await data.blob());
  } else {
    throw new TypeError('Unsupported data type for download');
  }

  // Create and trigger download
  const a = document.createElement('a');
  a.setAttribute('href', objectUrl);
  a.setAttribute('download', filename);
  a.setAttribute('target', '_blank');
  document.body.append(a);
  a.click();
  a.remove();
}
