import {createContext, useContext} from 'react';

/** @typedef {import('./useSorting.js').UseSorting} UseSorting */
/** @typedef {import('./useFilters.js').UseFilters} UseFilters */

function noOp() {}

/** @type {import('react').Context<TableContextValue>} */
export const TableContext = createContext({
  data: [],
  columns: [],
  sorting: null,
  toggleSorting: noOp,
  resetSorting: noOp,
  setAdvancedSorting: noOp,
  filters: [],
  toggleFilter: noOp,
  resetFilters: noOp,
  search_query: null,
  loading: true,
  actions: {},
});

/**
 * @typedef TableContextValue
 * @property {TableData} data The data to be displayed in the table.
 * @property {Array<ColumnDef>} columns Definitions for the table columns.
 * @property {boolean} loading Indicates if the table data is still loading.
 * @property {Record<string, TableAction>} [actions] Custom actions available for the table rows.
 * @property {string} [default_action] Default action to handle clicks on a row, if applicable.
 */

/**
 * React hook for distributing interactivity and information throughout
 * the table component without excessive prop drilling.
 *
 * @returns {TableContextValue & UseSorting & UseFilters}
 */
export function useTableContext() {
  return useContext(TableContext);
}

/**
 * @typedef {Array<object>} TableData
 * Array representing the data to be displayed in the table.
 */

/**
 * Defines a column in a data grid.
 *
 * @typedef ColumnDef
 * @property {string} name Name of the column.
 * @property {string|React.FC|null} Header Header as a string or component. `null` causes the header to the left to span into this column.
 * @property {string|number} [width] CSS grid width or a pixel value for the column.
 * @property {string|Array<SortingSpec>} [sort] The column name to sort by, or an array of sorting specifications.
 * @property {Array<{ label: React.ReactNode | string, value: string }>} [filters] Available filters for this column.
 * @property {(row: object) => any} [accessor] Function to extract the value for this column from a row.
 * @property {string|null} [action] Override the action performed when a cell is clicked. `null` disables actions.
 * @property {ColumnDefCellComponent} [Cell] Custom React component to render cells in this column.
 * @property {('left'|'center'|'right')} [justifyContent] Horizontal justification of cell content.
 */

/**
 * React component for rendering a table cell.
 *
 * @callback ColumnDefCellComponent
 * @param {object} props
 * @param {any} props.value The value to be displayed in the cell.
 * @returns {React.ReactNode} The rendered cell content.
 */

/**
 * Callback for defining a table action.
 *
 * @callback TableAction
 * @param {object} row The row data for which the action is triggered.
 */
