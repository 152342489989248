/**
 * Converts a value in cents to a formatted price string in euros.
 *
 * @param {number|string} cents - The amount in cents to be converted.
 * @param {Object} [options={}] - Additional options to customize the output.
 * @param {boolean} [options.useGrouping=true] - Whether to use grouping separators (e.g., 1.000 vs 1000).
 * @returns {string} - The formatted price string in euros with a comma as the decimal separator.
 *
 * @example
 * // Default usage with grouping separators
 * centsToPrice(123456); // "1.234,56"
 *
 * @example
 * // Disable grouping separators
 * centsToPrice(123456, { useGrouping: false }); // "1234,56"
 */
export function centsToPrice(cents, options = {}) {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    ...options,
  }).format(+cents / 100);
}
