import {useCallback, useState} from 'react';

/**
 * Custom hook to manage table filters.
 *
 * @param {Array<{ column: string, filter: Array<string> }>} initial_filters - The initial set of filters for the table.
 * @returns {{
 *   filters: Array<{ column: string, filter: Array<string> }>,
 *   toggleFilter: (column: string, filter: string) => void,
 *   resetFilters: () => void,
 *   isFilterActive: (column: string) => boolean,
 * }}
 * - `filters`: The current state of filters, each containing a column name and its associated active filters.
 * - `toggleFilter`: Function to toggle a specific filter for a column. If the filter exists, it will be removed; otherwise, it will be added.
 * - `resetFilters`: Function to reset all filters to the initial state.
 * - `isFilterActive`: Function to check if the filters for a column differ from the initial state.
 */
export function useFilters(initial_filters) {
  const [filters, setFilters] = useState(initial_filters ?? []);

  /**
   * Toggles a filter for a specific column.
   * Adds the filter if it's not active, removes it if it's already active.
   *
   * @param {string} column - The column for which the filter should be toggled.
   * @param {string} filter - The filter value to toggle.
   */
  const toggleFilter = useCallback(
    (column, filter) => {
      setFilters((prev_filters) => {
        const columnFilters = prev_filters.find((f) => f.column === column);
        if (columnFilters) {
          const updatedFilters = columnFilters.filter.includes(filter)
            ? columnFilters.filter.filter((f) => f !== filter)
            : [...columnFilters.filter, filter];
          return updatedFilters.length > 0
            ? prev_filters.map((f) =>
                f.column === column ? {column, filter: updatedFilters} : f,
              )
            : prev_filters.filter((f) => f.column !== column);
        }
        return [...prev_filters, {column, filter: [filter]}];
      });
    },
    [setFilters],
  );

  const resetFilters = useCallback(() => {
    setFilters(initial_filters ?? []);
  }, [initial_filters]);

  /**
   * Checks if the filters for a column are active (i.e., different from the initial state).
   *
   * @param {string} column - The column to check.
   * @returns {boolean} - `true` if the current filters differ from the initial state, otherwise `false`.
   */
  const isFilterActive = useCallback(
    (column) => {
      const current = filters.find((f) => f.column === column)?.filter ?? [];
      const defaultFilter =
        initial_filters.find((f) => f.column === column)?.filter ?? [];
      return (
        current.length !== defaultFilter.length ||
        !current.every((val) => defaultFilter.includes(val))
      );
    },
    [filters, initial_filters],
  );

  return {filters, toggleFilter, resetFilters, isFilterActive};
}
