import classNames from 'classnames';

import styles from './Spinner.module.scss';

export default function Spinner({className, size = 50}) {
  const containerStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <div
      className={classNames(styles.spinner_container, className)}
      style={containerStyle}>
      <svg className="spinner" viewBox="0 0 50 50" width={size} height={size}>
        <circle
          className={styles.background}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="8"
        />
        <circle
          className={styles.path}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="8"
        />
      </svg>
    </div>
  );
}
